import { useMutation } from '@apollo/client'
import { UPSERT_NEWSLETTER } from 'apollo/mutations/d8'
import { format } from 'date-fns'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { color, respondTo, remCalc } from 'styles/mixins'
import { isEmailValid } from 'utils/common'

import { GENERAL } from '../../config/app.config'

const InputBox = styled.div`
  background-color: ${color.white};
  border: ${remCalc(2)} solid ${(props) => (props.theme.error ? color.persimmon : color.black)};
  display: flex;
  justify-content: space-between;
  padding: ${remCalc(5)};
  font-size: ${remCalc(18)};
  line-height: ${remCalc(21)};

  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        font-size: ${remCalc(20)};
        line-height: ${remCalc(24)};
        max-width: ${remCalc(402)};
        margin-top: ${remCalc(16)};
      `}
  `}
`

const InputWrapper = styled.div`
  display: flex;
  padding-left: ${remCalc(19)};
`

const Input = styled.input`
  color: ${color.spanishGray};
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`

const Button = styled.button`
  background-color: ${color.black};
  color: ${color.white};
  font-size: ${remCalc(14)};
  font-weight: bold;
  letter-spacing: ${remCalc(0.88)};
  line-height: ${remCalc(16)};
  border-style: none;
  padding: ${remCalc(16, 18)};
  cursor: pointer;

  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        font-size: ${remCalc(16)};
        line-height: ${remCalc(19)};
        padding: ${remCalc(15, 20)};
      `}
  `}
`

const ErrorMsg = styled.div`
  color: ${color.persimmon};
  text-align: left;
  padding-top: ${remCalc(5)};
  font-size: ${remCalc(12)};
  line-height: ${remCalc(18)};
`

const invalidEmailErrorMessage = 'Please enter a valid email address'
const submitErrorMessage = 'There was a problem. Please enter a valid email and try again'

const FormEmailSubscribe = ({
  className,
  error,
  handleError,
  handleSubmit,
  keepSmall,
  newsletterClickGa4CustomizedParameters,
  newsletterGaData,
  newsletterId,
}) => {
  const [email, setEmail] = useState('')
  const [SubscribeNewsletter] = useMutation(UPSERT_NEWSLETTER, {
    onCompleted(d) {
      if (d.upsertNewsletterSubscriber.errors.length) {
        handleError(invalidEmailErrorMessage)
      } else {
        handleError('')
        handleSubmit()
      }
    },
    onError() {
      handleError(submitErrorMessage)
    },
  })

  const onSubmit = (e) => {
    e.preventDefault()
    const urlParams = new URLSearchParams(window.location.search)
    let device = ''
    const vpWidth = window.innerWidth
    if (vpWidth <= get(GENERAL.appConfig, 'breakpoints.xs', 0)) {
      device = 'mobile'
    } else if (vpWidth <= get(GENERAL.appConfig, 'breakpoints.sm', 0)) {
      device = 'tablet'
    } else {
      device = 'desktop'
    }

    const gaData = {
      from: 'young_post',
      metrics: {
        timestamp: format(new Date(), 'yyyy-MM-dd HH:mm'),
        device_category: device,
        event_category: get(newsletterGaData, 'event_category', ''),
        content_type: get(newsletterGaData, 'content_type', ''),
        section: get(newsletterGaData, 'section', ''),
        client_id: window.ga ? `${window.ga.getAll()[0].get('clientId')}` : '',
        source: urlParams.get('utm_source') || '',
        content: urlParams.get('utm_content') || '',
        medium: urlParams.get('utm_medium') || '',
        campaign: urlParams.get('utm_campaign') || '',
        url: window.location.href,
      },
    }

    setVariables({
      event: 'tracking',
      tracking: {
        category: get(newsletterGaData, 'event_category'),
        action: get(newsletterGaData, 'event_action', 'Newsletter/Sign Up'),
        label: get(newsletterGaData, 'event_label'),
      },
    })

    if (isEmailValid(email)) {
      SubscribeNewsletter({
        variables: {
          input: {
            mail: email,
            subscriptions: [
              {
                targetId: newsletterId,
                status: '1',
                source: JSON.stringify(gaData),
              },
            ],
            append: true,
          },
        },
        context: { clientName: 'd8' },
      })
    } else {
      handleError(invalidEmailErrorMessage)
    }
  }

  const handleSubmitButtonClick = () => {
    setVariables({
      event: 'GA4_tracking',
      category: 'nl',
      subcategory: 'sign_up',
      action: 'click',
      customized_parameters: newsletterClickGa4CustomizedParameters,
    })
  }

  return (
    <ThemeProvider theme={{ error: !!error, keepSmall }}>
      <form noValidate onSubmit={(e) => onSubmit(e)} className={className}>
        <InputBox>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <Button onClick={handleSubmitButtonClick}>Submit</Button>
        </InputBox>
        {error && <ErrorMsg>{error}</ErrorMsg>}
      </form>
    </ThemeProvider>
  )
}

FormEmailSubscribe.propTypes = {
  error: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  keepSmall: PropTypes.bool,
  newsletterClickGa4CustomizedParameters: PropTypes.object,
  newsletterGaData: PropTypes.object,
  newsletterId: PropTypes.number.isRequired,
}

FormEmailSubscribe.defaultProps = {
  error: '',
  keepSmall: false,
  newsletterClickGa4CustomizedParameters: {},
  newsletterGaData: {},
}

export default FormEmailSubscribe
